//
// Stepper
//

// Base
.stepper {
  // Content
  [data-ttz-stepper-element='info'],
  [data-ttz-stepper-element='content'] {
    display: none;

    &.current {
      display: flex;
    }
  }

  // Enable cursor pointer on clickable steppers
  .stepper-item[data-ttz-stepper-action='step'] {
    cursor: pointer;
  }

  // States
  & {
    [data-ttz-stepper-action='final'] {
      display: none;
    }

    [data-ttz-stepper-action='previous'] {
      display: none;
    }

    [data-ttz-stepper-action='next'] {
      display: inline-block;
    }

    [data-ttz-stepper-action='submit'] {
      display: none;
    }
  }

  &.first {
    [data-ttz-stepper-action='previous'] {
      display: none;
    }

    [data-ttz-stepper-action='next'] {
      display: inline-block;
    }

    [data-ttz-stepper-action='submit'] {
      display: none;
    }
  }

  &.between {
    [data-ttz-stepper-action='previous'] {
      display: inline-block;
    }

    [data-ttz-stepper-action='next'] {
      display: inline-block;
    }

    [data-ttz-stepper-action='submit'] {
      display: none;
    }
  }

  &.last {
    [data-ttz-stepper-action='final'] {
      display: inline-block;
    }

    [data-ttz-stepper-action='final'].btn-flex {
      display: flex;
    }

    [data-ttz-stepper-action='previous'] {
      display: inline-block;
    }

    [data-ttz-stepper-action='previous'].btn-flex {
      display: flex;
    }

    [data-ttz-stepper-action='next'] {
      display: none;
    }

    [data-ttz-stepper-action='next'].btn-flex {
      display: flex;
    }

    [data-ttz-stepper-action='submit'] {
      display: inline-block;
    }

    [data-ttz-stepper-action='submit'].btn-flex {
      display: flex;
    }
  }
}
