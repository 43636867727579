.form-rate {
  &.form-rate-md {
    font-size: 25px;
  }

  // Solid style
  &.form-rate-solid {
    .rc-rate-star-zero {
      color: $light;
    }
  }

  // White style
  &.form-rate-white {
    .rc-rate-star-zero {
      color: $white;
    }
  }

  // Flush
  &.form-rate-flush {
  }
}
