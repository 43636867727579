@import '~react-datepicker/dist/react-datepicker.css';

.react-datepicker-wrapper {
  .input-group > & {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
  }
}

.input-group
  > .react-datepicker-wrapper:not(:first-child):not(.dropdown-menu):not(
    .valid-tooltip
  ):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback)
  .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
