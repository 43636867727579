//
// Transform
//

.transform-90 {
  transform: rotate(90deg);
  transform-origin: right top;
}

.transform-270 {
  transform: rotate(270deg);
  transform-origin: left top;
}

.transform-hover-scale-sm {
  transition: all 0.3s ease-in-out !important;

  &:hover {
    transform: scale(1.025);
  }
}

.transform-hover-scale {
  transition: all 0.3s ease-in-out !important;

  &:hover {
    transform: scale(1.05);
  }
}

.transform-hover-scale-lg {
  transition: all 0.3s ease-in-out !important;

  &:hover {
    transform: scale(1.1);
  }
}
