.form-btn-group-radio {
  @extend .btn-group;
  @include border-radius($input-border-radius, 0);

  .btn {
    @extend .btn-light;
  }

  // Solid style
  &.form-btn-group-radio-solid {
    .btn {
      @extend .btn-light;
    }
  }

  // White style
  &.form-btn-group-radio-white {
    box-shadow: $box-shadow-sm;
    transition: $transition-input;

    .btn {
      @extend .btn-light;
    }

    &:active,
    &.active,
    &:focus,
    &.focus {
      box-shadow: $box-shadow;
    }
  }

  // Flush
  &.form-btn-group-radio-flush {
    .btn {
      @include input-reset();
    }
  }
}
