@import "@yaireo/tagify/dist/tagify.css";

.tagify__tag {
  z-index: initial !important;
}

.tagify[class*="form-control"] {
  @extend .form-control;
}

.tagify[class*="form-control-sm"] {
  @extend .form-control-sm !optional;
}

.tagify[class*="form-control-lg"] {
  @extend .form-control-lg !optional;
}

.tagify[class*="form-control-solid"] {
  @extend .form-control-solid !optional;
}

.tagify[class*="form-control-white"] {
  @extend .form-control-white !optional;
}

.tagify[class*="form-control-flush"] {
  @extend .form-control-flush !optional;
}

.tagify[class*="form-control-shadow"] {
  @extend .form-control-shadow !optional;
}
