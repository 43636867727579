.dropzone {
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
  appearance: none; // Fix appearance for date inputs in Safari
  display: flex;
  flex-wrap: wrap;
  position: relative;

  .dz-preview {
    width: 120px;
    height: 120px;
    position: relative;
    border-radius: $border-radius-sm;

    .dz-image {
      width: 100%;
      height: 100%;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .dz-details {
      z-index: 20;
      position: absolute;
      top: 0;
      left: 0;
      font-size: 13px;
      min-width: 100%;
      max-width: 100%;
      padding: 2em 1em;
      text-align: center;
      color: rgba(0, 0, 0, 0.9);
      line-height: 150%;

      .dz-filename span,
      .dz-size span {
        background-color: rgba(255, 255, 255, 0.4);
        padding: 0 0.4em;
        border-radius: 3px;
      }

      .dz-filename {
        white-space: nowrap;

        &:not(:hover) {
          overflow: hidden;
          text-overflow: ellipsis;

          span {
            border: 1px solid transparent;
          }
        }
      }

      .dz-size {
        margin-bottom: 1em;
        font-size: 16px;
      }
    }

    .dz-remove {
      cursor: pointer;
    }

    .dz-progress {
      opacity: 1;
      z-index: 1000;
      pointer-events: none;
      position: absolute;
      height: 16px;
      left: 50%;
      top: 50%;
      margin-top: -8px;
      width: 80px;
      margin-left: -40px;
      background: rgba($white, 0.9);
      border-radius: 8px;
      overflow: hidden;

      .dz-upload {
        background: #333;
        background: linear-gradient(to bottom, #666, #444);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
        transition: width 0.3s ease-in-out;
      }
    }

    &:hover {
      background-color: $white;
    }
  }

  &.dropzone-solid {
    background-color: $input-solid-bg;
    border-color: $input-solid-bg;
    @include placeholder($input-solid-placeholder-color);
    color: $input-solid-color;
    transition: $transition-input;

    .dropdown.show > &,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: $input-solid-bg-focus;
      border-color: $input-solid-bg-focus;
      color: $input-solid-color;
      transition: $transition-input;
    }
  }

  // White style
  &.dropzone-white {
    background-color: $body-bg;
    border-color: $body-bg;

    .dropdown.show > &,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: $body-bg;
      border-color: $body-bg;
    }
  }

  // White style
  &.dropzone-shadow {
    box-shadow: $box-shadow !important;

    &.shadow-xs {
      box-shadow: $box-shadow-xs !important;
    }

    &.shadow-sm {
      box-shadow: $box-shadow-sm !important;
    }

    &.shadow-lg {
      box-shadow: $box-shadow-lg !important;
    }
  }

  // Flush
  &.dropzone-flush {
    @include input-reset();
  }
}
