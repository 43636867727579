//
// React Select
//

.form-react-select {
  .form-react-select__control {
    border: $input-border-width solid $input-border-color;
    background-color: $input-bg;
    background-clip: padding-box;
    appearance: none; // Fix appearance for date inputs in Safari

    @include border-radius($input-border-radius, 0);
    @include transition($input-transition);

    .form-react-select__value-container {
      line-height: $input-line-height;
      font-weight: $input-font-weight;
      @include font-size($input-font-size);
      font-family: $input-font-family;
      padding: $input-padding-y $input-padding-x * 0.8;

      .form-react-select__single-value {
        color: $input-color;
      }

      .form-react-select__placeholder {
        white-space: nowrap;
        color: $gray-400;
      }

      [class$='-Input'],
      div:last-child {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .form-react-select__indicators {
      .form-react-select__indicator-separator {
        opacity: 0;
      }

      .form-react-select__dropdown-indicator.form-react-select__dropdown-indicator {
        color: $gray-600;
      }
    }

    &.form-react-select__control--is-focused,
    .dropdown.show > &,
    &:active,
    &.active,
    &:focus,
    &.focus {
      // background-color: $input-solid-bg-focus;
      border-color: $input-focus-border-color;
      // color: $input-solid-color;
      // transition: $transition-input;
      box-shadow: none;
    }
  }

  // Solid style
  &.form-react-select-solid .form-react-select__control {
    background-color: $input-solid-bg;
    border-color: $input-solid-bg;
    transition: $transition-input;

    .form-react-select__value-container {
      color: $input-solid-color;
      @include placeholder($input-solid-placeholder-color);
    }

    &.form-react-select__control--is-focused,
    .dropdown.show > &,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: $input-solid-bg-focus;
      border-color: $input-solid-bg-focus;
      color: $input-solid-color;
      transition: $transition-input;
    }
  }

  // White style
  &.form-react-select-white .form-react-select__control {
    background-color: $white;
    border-color: $white;

    &.form-react-select__control--is-focused,
    .dropdown.show > &,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: $white;
      border-color: $white;
    }
  }

  // Shadow style
  &.form-react-select-shadow .form-react-select__control {
    box-shadow: $box-shadow !important;
  }

  &.form-react-select-shadow-xs .form-react-select__control {
    box-shadow: $box-shadow-xs !important;
  }

  &.form-react-select-shadow-sm .form-react-select__control {
    box-shadow: $box-shadow-sm !important;
  }

  &.form-react-select-shadow-lg .form-react-select__control {
    box-shadow: $box-shadow-lg !important;
  }

  // Flush
  &.form-react-select-flush .form-react-select__control {
    @include input-reset();
  }

  .form-react-select__menu {
    z-index: 2;
  }
}
