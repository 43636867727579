@media (max-width: 767px) {
  .table-responsive .dropdown-menu {
    position: static !important;
  }
}

@media (min-width: 1024px) {
  .table-responsive {
    overflow: inherit;
  }
}
