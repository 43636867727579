$cursors: auto default none context-menu help pointer progress wait cell
  crosshair text vertical-text alias copy move no-drop not-allowed all-scroll
  col-resize row-resize n-resize e-resize s-resize w-resize ns-resize ew-resize
  ne-resize nw-resize se-resize sw-resize nesw-resize nwse-resize zoom-in
  zoom-out zoom-grab zoom-grabing;

@each $cursor in $cursors {
  .cursor-#{$cursor} {
    cursor: $cursor !important;
  }
}
