.image-input {
  width: 120px;
  height: 120px;
  background-image: url('/placeholders/user.svg');

  .image-input-wrapper {
    width: 100% !important;
    height: 100% !important;
  }

  &.image-input-solid {
    background-color: var(--bs-gray-100);
    border: 3px solid var(--bs-gray-100);
  }

  &.image-input-shadow {
    border: 3px solid var(--bs-body-bg);
    box-shadow: var(--bs-box-shadow);
  }
}
