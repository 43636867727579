$object-fit-properties: fill contain cover none scale-down;
$object-position-properties: initial center;

@each $property in $object-fit-properties {
  .obj-fit-#{$property} {
    object-fit: $property;
  }

  .object-fit-#{$property} {
    object-fit: $property;
  }
}

@each $property in $object-position-properties {
  .obj-position-#{$property} {
    object-position: $property;
  }

  .object-position-#{$property} {
    object-position: $property;
  }
}
