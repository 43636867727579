// Fix drawer on aside hidding on tablet-and-desktop
@include media-breakpoint-up(lg) {
  .aside.drawer {
    transform: none !important;
    left: initial !important;
  }
}

.drawer {
  &.drawer-top {
    top: 0;
    transform: translateY(-100%);
    width: 100%;
  }
}
