@each $name, $color in $theme-colors {
  // Base color
  .shadow-#{$name}-xs {
    box-shadow: 0px 0px 30px rgba($color, 0.055);
  }
  .shadow-#{$name}-sm {
    box-shadow: 0 0.1rem 1rem 0.25rem rgba($color, 0.05);
  }
  .shadow-#{$name} {
    box-shadow: 0 0.5rem 1.5rem 0.5rem rgba($color, 0.075);
  }
  .shadow-#{$name}-lg {
    box-shadow: 0 1rem 2rem 1rem rgba($color, 0.1);
  }

  // Hover
  .shadow-hover-#{$name}-xs {
    transition: box-shadow 0.15s ease-in-out;

    &:hover {
      box-shadow: 0px 0px 30px rgba($color, 0.055);
    }
  }

  .shadow-hover-#{$name}-sm {
    transition: box-shadow 0.15s ease-in-out;

    &:hover {
      box-shadow: 0 0.1rem 1rem 0.25rem rgba($color, 0.05);
    }
  }

  .shadow-hover-#{$name} {
    transition: box-shadow 0.15s ease-in-out;

    &:hover {
      box-shadow: 0 0.5rem 1.5rem 0.5rem rgba($color, 0.075);
    }
  }

  .shadow-hover-#{$name}-lg {
    transition: box-shadow 0.15s ease-in-out;

    &:hover {
      box-shadow: 0 1rem 2rem 1rem rgba($color, 0.1);
    }
  }
}
