@import "~react-quill/dist/quill.snow.css";

.form-editor {
  display: flex;
  flex-direction: column;

  .ql-toolbar,
  .ql-container {
    border: $input-border-width solid $input-border-color;
    background-color: $input-bg;
    font-family: $font-family-base;
  }

  .ql-toolbar {
    border-top-right-radius: $input-border-radius;
    border-top-left-radius: $input-border-radius;
  }

  .ql-container {
    max-height: calc(100% - 41px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    border-bottom-right-radius: $input-border-radius;
    border-bottom-left-radius: $input-border-radius;

    .ql-editor {
      flex-grow: 1;
    }
  }

  // Solid style
  &.form-editor-solid {
    .ql-toolbar,
    .ql-container {
      background-color: $input-solid-bg;
      border-color: $input-solid-bg;
    }
  }

  // White style
  &.form-editor-white {
    .ql-toolbar,
    .ql-container {
      background-color: $white;
      border-color: $white;
      box-shadow: $box-shadow-sm;
    }
  }

  // Flush
  &.form-editor-flush {
    .ql-toolbar,
    .ql-container {
      @include input-reset();
    }
  }
}

.form-editor-toolbar-bottom {
  flex-direction: column-reverse;

  .ql-container {
    border-top: $input-border-width solid $input-border-color !important;
    border-top-right-radius: $input-border-radius;
    border-top-left-radius: $input-border-radius;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .ql-toolbar {
    border-bottom-right-radius: $input-border-radius;
    border-bottom-left-radius: $input-border-radius;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top: 0px;
  }
}
