.text-titleize:first-letter {
  text-transform: uppercase;
}

// Text for social networks colors
@each $name, $value in $social-colors {
  .text-#{$name} {
    color: get($value, base);
  }
}
