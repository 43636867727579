// Responsive helpers
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    // Background sizes
    .bgi-size#{$infix}-auto {
      background-size: auto !important;
    }

    .bgi-size#{$infix}-cover {
      background-size: cover !important;
    }

    .bgi-size#{$infix}-contain {
      background-size: contain !important;
    }

    // Background attachment
    .bgi-attachment#{$infix}-fixed {
      background-attachment: fixed !important;
    }

    .bgi-attachment#{$infix}-auto {
      background-attachment: auto !important;
    }
  }
}

// Mix blend mode
$mix-blend-modes: normal multiply screen overlay darken lighten color-dodge
  color-burn hard-light soft-light difference exclusion hue saturation color
  luminosity initial inherit unset;

@each $mix-blend-mode in $mix-blend-modes {
  .mix-blend-mode-#{$mix-blend-mode} {
    mix-blend-mode: $mix-blend-mode;
  }
}

// Background gradient
