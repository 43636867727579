//
// Form Control
//

.form-control {
  // Shadow style
  &.form-control-shadow {
    box-shadow: $box-shadow !important;

    &.shadow-xs {
      box-shadow: $box-shadow-xs !important;
    }

    &.shadow-sm {
      box-shadow: $box-shadow-sm !important;
    }

    &.shadow-lg {
      box-shadow: $box-shadow-lg !important;
    }
  }

  // White style
  &.form-control-white {
    border-color: $white;

    .dropdown.show > &,
    &:active,
    &.active,
    &:focus,
    &.focus {
      border-color: $white;
    }
  }
}
